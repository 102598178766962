import { ReactChild, Suspense } from 'react';
import { CustomErrorBoundaryProps, ErrorBoundary } from '@/shared/components/common/ErrorBoundary';

type Props = CustomErrorBoundaryProps & {
  pendingFallback?: ReactChild;
};

export const AsyncBoundary = ({
  pendingFallback = <div>로딩</div>,
  children,
  ...errorBoundaryProps
}: Props) => (
  <ErrorBoundary {...errorBoundaryProps}>
    <Suspense fallback={pendingFallback}>{children}</Suspense>
  </ErrorBoundary>
);
