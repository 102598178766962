import { PropsWithChildren, PropsWithRef } from 'react';
import {
  ErrorBoundary as ReactErrorBoundary,
  ErrorBoundaryProps,
} from 'react-error-boundary';

export type CustomErrorBoundaryProps = PropsWithRef<
  PropsWithChildren<ErrorBoundaryProps>
> & {
  errorCallback?: () => void;
};

const errorHandler = (errorCallback?: () => void) => (error: Error) => {
  // TODO: add sentry
  console.log(error);
  errorCallback?.();
};

export const ErrorBoundary = ({
  children,
  errorCallback,
  ...restProps
}: CustomErrorBoundaryProps) => (
  <ReactErrorBoundary {...restProps} onError={errorHandler(errorCallback)}>
    {children}
  </ReactErrorBoundary>
);
