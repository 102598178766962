class UserInfoStorage {
  private authToken = '';

  private userId = '';

  setAuthToken = (token: string) => {
    this.authToken = token;
  };

  getAuthToken = () => this.authToken;

  setUserId = (userId: string) => {
    this.userId = userId;
  };

  getUserId = () => this.userId;
}

export const userInfoStorage = new UserInfoStorage();
