import React, { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';

import { Snackbar, SnackbarOptions } from '@/shared/components/Snackbar';

interface SnackbarContextProps {
  open: (options: SnackbarOptions) => void;
  close: () => void;
}

const SnackbarContext = createContext<SnackbarContextProps | undefined>(undefined);

export const SnackbarProvider = ({ children }: { children: ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [snackbarOptions, setSnackbarOptions] = useState<SnackbarOptions>({ message: '' });

  const triggerSnackbar = (options: SnackbarOptions) => {
    setSnackbarOptions(options);
    setIsOpen(true);
  };

  const open = useCallback((options: SnackbarOptions) => {
    if (isOpen) {
      setIsOpen(false);

      return setTimeout(() => {
        triggerSnackbar(options);
      }, INTERVAL_DURATION);
    }

    triggerSnackbar(options);
  }, []);

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  const controls = useMemo(() => ({ open, close }), [open, close]);

  return (
    <SnackbarContext.Provider value={controls}>
      {children}
      <Snackbar options={snackbarOptions} isOpen={isOpen} close={close} />
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => {
  const controls = useContext(SnackbarContext);

  if (controls === undefined) {
    throw new Error('SnackbarContext 안에서 사용해주세요.');
  }

  return controls;
};

const INTERVAL_DURATION = 250;
