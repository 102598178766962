import styled from '@emotion/styled';
import { animated, useTransition } from '@react-spring/web';
import { cssVar, rgba } from 'polished';
import React, { MouseEventHandler } from 'react';

import { Portal } from '@/shared/components/Portal';
import { useHideBody } from '@/shared/hooks/useHideBody';

interface Props {
  isOpen: boolean;
  onDimClose?: MouseEventHandler<HTMLDivElement>;
  dimDuration?: number;
}

// eslint-disable-next-line react/display-name
export const Overlay = React.forwardRef(
  ({ isOpen, onDimClose, dimDuration = 300 }: Props) => {
    const transitions = useTransition(isOpen, {
      from: { opacity: 0 },
      enter: { opacity: 1 },
      leave: { opacity: 0 },
      delay: 0,
      duration: dimDuration,
    });

    const { bind } = useHideBody(isOpen);

    return (
      <Portal>
        {transitions(
          ({ opacity }, item) =>
            item && (
              <Wrapper {...bind}>
                <Dim
                  style={{ opacity }}
                  onClick={event => {
                    event.preventDefault();
                    event.stopPropagation();

                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    onDimClose?.();
                  }}
                />
              </Wrapper>
            )
        )}
      </Portal>
    );
  }
);

const Wrapper = styled.div`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: 'auto';
  width: 100vw;
  height: 100vh;
  z-index: 9990;
  position: fixed;
  content: "''";
  -webkit-tap-highlight-color: 'transparent';
`;

// TODO: background에 theme.colors.$gray900을 할 경우 다크모드에서 흰색으로 dim이 되어 어색함. 다크모드에서 dim 배경이 어떤 색으로 되는 것이 이상적인지 제임스와 이야기할 것
const Dim = styled(animated.div)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${rgba(cssVar('--seed-semantic-color-overlay-dim'), 0.5)};
`;
