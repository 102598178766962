import styled from '@emotion/styled';
import { animated, useSpring } from '@react-spring/web';
import { vars } from '@seed-design/design-token';
import { ReactNode, useEffect, useRef } from 'react';

export interface SnackbarOptions {
  message: ReactNode | string;
  action?: { label: string; onClick: () => void };
  closeOnClick?: boolean;
}

interface Props {
  options: SnackbarOptions;
  close: () => void;
  isOpen: boolean;
}

export const Snackbar = ({
  options: { message, action, closeOnClick = true },
  close,
  isOpen,
}: Props) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const spring = useSpring({
    scale: isOpen ? 1 : 0,
    config: {
      delay: 0,
      duration: ANIMATION_DURATION,
    },
  });

  useEffect(() => {
    if (isOpen) {
      setTimeout(close, EXPOSURE_DURATION);
    }
  }, [isOpen]);

  return (
    <Wrapper isOpen={isOpen}>
      <SnackbarLayout
        ref={ref}
        style={{
          willChange: 'transform',
          transform: spring.scale?.to(v => `scale(${v})`),
        }}
        onClick={event => {
          event.preventDefault();
          event.stopPropagation();

          if (closeOnClick) {
            close();
          }
        }}
      >
        <Message>{message}</Message>
        {action && (
          <ActionButton
            onClick={() => {
              action.onClick();
              close();
            }}
          >
            {action.label}
          </ActionButton>
        )}
      </SnackbarLayout>
    </Wrapper>
  );
};

const Wrapper = styled.div<Pick<Props, 'isOpen'>>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 9999;
  pointer-events: ${({ isOpen }) => (isOpen ? 'auto' : 'none')};
  padding: 0 8px;
  bottom: calc(constant(safe-area-inset-bottom) + 4rem);
  bottom: calc(env(safe-area-inset-bottom) + 4rem);
`;

const SnackbarLayout = styled(animated.div)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${vars.$scale.color.gray900};
  opacity: 0.95;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.28);
  border-radius: 4px;
  padding: 12px 8px;
`;

const Message = styled.p`
  width: 100%;
  padding: 0 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.875rem;
  line-height: 150%;
  letter-spacing: -0.02em;
  color: ${vars.$semantic.color.paperDefault};
  flex: 1;
`;

const ActionButton = styled.button`
  padding: 1.5px 6px;
  font-size: 0.875rem;
  line-height: 150%;
  letter-spacing: -0.02em;
  font-weight: bold;
  color: ${vars.$scale.color.carrot500};
`;

const ANIMATION_DURATION = 110;
const EXPOSURE_DURATION = 3500;
