import { QueryClient, QueryClientProvider } from 'react-query';

import Routes from '@/shared/routes';

import { GlobalStyles } from '@/shared/styles/global';
import '@/shared/styles/globalSeedDesign';
import { useInitUserInfo } from '@/shared/hooks/useInitUserInfo';
import { REACT_APP_ENV } from './shared/constants/common';
import { SnackbarProvider } from './shared/providers/Snackbar';
import { DialogProvider } from './shared/providers/Dialog';
import { BottomSheetProvider } from './shared/providers/BottomSheet';

const queryClient = new QueryClient();

if (REACT_APP_ENV === 'alpha') {
  import('@/shared/utils/v-console').then(initVConsole => initVConsole.default());
}

function App() {
  useInitUserInfo();

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider>
          <DialogProvider>
            <BottomSheetProvider>
              <Routes />
            </BottomSheetProvider>
          </DialogProvider>
        </SnackbarProvider>
      </QueryClientProvider>
      <GlobalStyles />
    </>
  );
}

export default App;
