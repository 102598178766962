import { userInfoStorage } from '@/shared/utils/user-info-storage';
import { bridge } from '@/shared/utils/bridge';
import { REACT_APP_ENV } from '@/shared/constants/common';

export const useInitUserInfo = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { userId, authToken } = userInfoResource.read();

  userInfoStorage.setAuthToken(authToken);
  userInfoStorage.setUserId(userId);
};

type Status = 'pending' | 'rejected' | 'resolved';
type Result =
  | {
      userId: string;
      authToken: string;
    }
  | Error
  | undefined;

const makeUserInfoResource = () => {
  let status: Status = 'pending';
  let result: Result;

  const suspender = (async () => {
    try {
      if (REACT_APP_ENV === 'dev') {
        console.info('[withUserInfo] 브라우저 환경에서는 .env 내 AUTH_TOKEN 값을 return 해요.');

        status = 'resolved';
        result = {
          userId: process.env.USER_ID || '',
          authToken: process.env.AUTH_TOKEN || '',
        };

        return;
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { info: { user: { id: userId, authToken }}} = await bridge.getUserInfo({});

      status = 'resolved';
      result = {
        userId,
        authToken,
      };
    } catch (error: unknown) {
      status = 'rejected';
      result = error as Error;
    }
  })();

  return {
    read() {
      switch (status) {
        case 'resolved':
          return result;
        case 'rejected':
          throw result;
        case 'pending':
        default: {
          throw suspender;
        }
      }
    },
  };
};

const userInfoResource = makeUserInfoResource();
