import React from 'react';
import ReactDOM from 'react-dom';

import '@karrotframe/navigator/index.css';
import '@karrotmarket/design-token/colors/light.css';
import '@karrotmarket/design-token/colors/dark.css';
import '@daangn/karrot-clothes/lib/index.css';

import '@/shared/styles/index.css';
import '@/shared/styles/reset.css';

import '@karrotframe/pulltorefresh/index.css';
import '@karrotframe/tabs/index.css';

import App from './App';
import { AsyncBoundary } from '@/shared/components/common/AsyncBoundary';

ReactDOM.render(
  <React.StrictMode>
    <AsyncBoundary pendingFallback={<></>} fallbackRender={() => <></>}>
      <App />
    </AsyncBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);
