import styled from '@emotion/styled';
import { animated, useSpring } from '@react-spring/web';
import { ReactNode, useRef } from 'react';

interface Props {
  isOpen: boolean;
  children: ReactNode;
  dimDuration?: number;
}

export const BottomSheet = ({ isOpen, children, dimDuration = 200 }: Props) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const spring = useSpring({
    translateY: isOpen ? '0' : '100%',
    config: {
      delay: 0,
      duration: dimDuration,
    },
  });

  return (
    <Wrapper
      ref={ref}
      style={{
        willChange: 'transform',
        transform: spring.translateY?.to(v => `translateY(${v})`),
      }}
    >
      {children}
    </Wrapper>
  );
};

const Wrapper = styled(animated.div)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  width: 100%;
`;
