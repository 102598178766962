import { css, Global } from '@emotion/react';
import { vars } from '@seed-design/design-token';
import React from 'react';

export const GlobalStyles = () => {
  return (
    <Global
      styles={css`
        #root * {
          --kf_pulltorefresh_backgroundLowColor: ${vars.$semantic.color.paperDefault} !important;
          --pull-to-refresh_backgroundColor: ${vars.$semantic.color.paperDefault} !important;
          --color-carrot500: ${vars.$scale.color.carrot500} !important;
          --kf_navigator_backgroundColor: ${vars.$semantic.color.paperDefault} !important;
          --kf_navigator_navbar-iconColor: ${vars.$scale.color.gray900} !important;
          --kf_navigator_navbar-borderColor: ${vars.$semantic.color.divider3} !important;
          --kf_navigator_navbar-center-textColor: ${vars.$scale.color.gray900} !important;
        }
      `}
    />
  );
};
