import { BrowserRouter } from 'react-router-dom';
import { Navigator, Screen } from '@karrotframe/navigator';
import { useCallback } from 'react';
import { bridge } from '../utils/bridge';
import { withLazyLoad } from '../hoc/withLazyLoad';

const PageHome = withLazyLoad(() => import('@/features/home'));

const Routes = () => {
  const handleClose = useCallback(() => {
    bridge.closeRouter({});
  }, []);

  const handleDepthChange = useCallback((depth: number) => {
    if (depth > 0) {
      
      bridge.styleCurrentRouter({router: { backSwipable: false, navbar: false, scrollable: false }});
    } else {
      bridge.styleCurrentRouter({router: { backSwipable: true, navbar: false, scrollable: false }});
    }
  }, []);

  return (
    <BrowserRouter>
      <Navigator
        useCustomRouter
        onDepthChange={handleDepthChange}
        onClose={handleClose}
      >
        <Screen path="/" component={PageHome} />
      </Navigator>
    </BrowserRouter>
  );
};

export default Routes;
