import styled from '@emotion/styled';
import { animated, useSpring } from '@react-spring/web';
import { MouseEventHandler, ReactNode, useRef } from 'react';

interface Props {
  isOpen: boolean;
  children: ReactNode;
  dimDuration?: number;
  onDimClose?: MouseEventHandler<HTMLDivElement>;
}

export const DialogWrapper = ({ isOpen, children, onDimClose, dimDuration = 150 }: Props) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const spring = useSpring({
    scale: isOpen ? 1 : 0,
    config: {
      delay: 0,
      duration: dimDuration,
    },
  });

  return (
    <Wrapper
      ref={ref}
      style={{
        willChange: 'transform',
        transform: spring.scale?.to(v => `scale(${v})`),
      }}
      onClick={event => {
        event.preventDefault();
        event.stopPropagation();

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onDimClose?.();
      }}
    >
      <div
        onClick={event => {
          event.stopPropagation();
        }}
      >
        {children}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled(animated.div)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
