import React, { ComponentType, lazy, Suspense } from 'react';

export function withLazyLoad<T extends ComponentType>(h: () => Promise<{ default: T }>) {
  const Component = lazy(h);

  // eslint-disable-next-line react/display-name
  return (props: any) => (
    <Suspense fallback={null}>
      <Component {...props} />
    </Suspense>
  );
}
