import React, {
  createContext,
  MouseEventHandler,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { DialogWrapper } from '@/shared/components/Dialog/wrapper';
import { Overlay } from '@/shared/components/Overlay';

interface DialogOptions {
  element: ReactNode;
  onOpened?: () => void;
  onClosed?: () => void;
  onDimClose?: MouseEventHandler<HTMLDivElement>;
}

interface DialogContextProps {
  open: (options: DialogOptions) => void;
  close: () => void;
}

const DialogContext = createContext<DialogContextProps | undefined>(undefined);

export const DialogProvider = ({ children }: { children: ReactNode }) => {
  const [isOpen, setIsOpen] = useState<boolean>();
  const [options, setOptions] = useState<DialogOptions>({
    element: <></>,
  });

  const isOpenRef = useRef(isOpen);

  const open = useCallback((options: DialogOptions) => {
    if (isOpenRef.current) {
      setIsOpen(false);
    }

    setOptions({ ...options });
    setIsOpen(true);
  }, []);

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  const controls = useMemo(() => ({ open, close }), [open, close]);

  const handleDimClose = (e: React.MouseEvent<HTMLDivElement>) => {
    if (options && options.onDimClose) {
      return options.onDimClose(e);
    }

    close();
  };

  useEffect(() => {
    isOpenRef.current = isOpen;
  }, [isOpen]);

  return (
    <DialogContext.Provider value={controls}>
      {children}
      <DialogWrapper isOpen={!!isOpen} onDimClose={handleDimClose} dimDuration={ANIMATION_DURATION}>
        {options.element}
      </DialogWrapper>
      <Overlay isOpen={!!isOpen} dimDuration={ANIMATION_DURATION} />
    </DialogContext.Provider>
  );
};

export const useDialog = () => {
  const controls = useContext(DialogContext);

  if (controls === undefined) {
    throw new Error('DialogContext 안에서 사용해주세요.');
  }

  return controls;
};

const ANIMATION_DURATION = 150;
