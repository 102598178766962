import React, {
  createContext,
  MouseEventHandler,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { BottomSheet } from '@/shared/components/BottomSheet';
import { Overlay } from '@/shared/components/Overlay';

interface BottomSheetOptions {
  element: ReactNode;
  onOpened?: () => void;
  onClosed?: () => void;
  onDimClose?: MouseEventHandler<HTMLDivElement>;
}

interface BottomSheetContextProps {
  open: (options: BottomSheetOptions) => void;
  close: () => void;
}

const BottomSheetContext = createContext<BottomSheetContextProps | undefined>(undefined);

export const BottomSheetProvider = ({ children }: { children: ReactNode }) => {
  const [isOpen, setIsOpen] = useState<boolean>();
  const [options, setOptions] = useState<BottomSheetOptions>({
    element: <></>,
  });

  const isOpenRef = useRef(isOpen);

  const open = useCallback((options: BottomSheetOptions) => {
    if (isOpenRef.current) {
      setIsOpen(false);
    }

    setOptions({ ...options });
    setIsOpen(true);
  }, []);

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  const controls = useMemo(() => ({ open, close }), [open, close]);

  const handleDimClose = (e: React.MouseEvent<HTMLDivElement>) => {
    if (options && options.onDimClose) {
      return options.onDimClose(e);
    }

    close();
  };

  useEffect(() => {
    isOpenRef.current = isOpen;
  }, [isOpen]);

  return (
    <BottomSheetContext.Provider value={controls}>
      {children}
      <BottomSheet isOpen={!!isOpen} dimDuration={ANIMATION_DURATION}>
        {options.element}
      </BottomSheet>
      <Overlay isOpen={!!isOpen} onDimClose={handleDimClose} dimDuration={ANIMATION_DURATION} />
    </BottomSheetContext.Provider>
  );
};

export const useBottomSheet = () => {
  const controls = useContext(BottomSheetContext);

  if (controls === undefined) {
    throw new Error('BottomSheetContext 안에서 사용해주세요.');
  }

  return controls;
};

const ANIMATION_DURATION = 200;
