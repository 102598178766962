import React, { useRef } from 'react';

export const useHideBody = (showing: boolean) => {
  const overlayRef = useRef<HTMLDivElement | null>(null);
  const returnRef = useRef<(() => void) | null>(null);

  const setElementsHidden = () => {
    const elements = document.querySelectorAll('body > *');

    const originals: {
      element: Element;
      attr: string | null;
    }[] = [];

    elements.forEach(element => {
      if (element.contains(overlayRef.current)) {
        return;
      }

      const hidden = element.getAttribute('aria-hidden');

      if (hidden !== 'true') {
        originals.push({ element, attr: hidden });
        element.setAttribute('aria-hidden', 'true');
      }
    });

    return () => {
      originals.forEach(({ element, attr }) =>
        attr ? element.setAttribute('aria-hidden', attr) : element.removeAttribute('aria-hidden')
      );
    };
  };

  React.useEffect(() => {
    if (showing) {
      returnRef.current = setElementsHidden();
    } else if (returnRef.current) {
      returnRef.current();
    }
  }, [showing]);

  React.useEffect(() => {
    if (returnRef.current) {
      returnRef.current();
    }
  }, []);

  return {
    bind: { ref: overlayRef },
  };
};
